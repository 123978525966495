const ContactForm = () => {
    const form = document.getElementById("Form_ContactForm");

    if (form === null) {
        return;
    }

    form.addEventListener("submit", function(event) {
        event.preventDefault();
        
        const formData = new FormData(form);

        fetch("contactform", {
            method: "POST",
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            // Handle the response data (if any)
            if (data.success) {
                const divElement = document.querySelector(".block-contact__form");
                divElement.innerHTML = '<h3>Thank you for your inquiry, we will get back to you shortly.</h3>';
            } else {
                const divElement = document.querySelector(".block-contact__form");
                divElement.innerHTML = '<h3>An error occurred on the server; please try again later.</h3>';
            }
        })
        .catch(error => {
            // Handle errors
            console.error(error);
        });
    });
};

export default ContactForm;

export const fadeOut = (el, smooth = true, displayStyle = 'none') => {
  if (smooth) {
      let opacity = el.style.opacity;
      let request;

      const animation = () => {
          el.style.opacity = opacity -= 0.04;
          if (opacity <= 0) {
              opacity = 0;
              el.style.display = displayStyle;
              cancelAnimationFrame(request);
          }
      };

      const rAf = () => {
              request = requestAnimationFrame(rAf);
              animation();
      };
      rAf();

  } else {
      el.style.opacity = 0;
  }
};
import {saveProgress} from "../services/UserServices"

const Portal = () => {
  const portalSection = document.querySelector(".portal-section");

  const video = document.getElementById('videoPlayer');
  const playBtn = document.getElementById('playBtn');
  if (video) {
    // Hide default video controls
    video.controls = false;

    let duration = 0;
    let current_time = 0;
    let compare_time = 0;
    let percentage = 0;
    let played_time = 0;

    playBtn.addEventListener('click', function () {
      video.play();
      video.controls = true;
      playBtn.classList.add('hidden');
    });

    video.addEventListener('play', function () {
      playBtn.classList.add('hidden');

      video.style.width = '100%';
      video.style.height = '665px';
      video.style.maxWidth = '1070px';
      video.style.objectFit = 'contain';

    });

    video.addEventListener('pause', function () {
      playBtn.classList.remove('hidden');

      const account = window.localStorage.getItem("account");
      const currentURL = window.location.href;
      const queryString = currentURL.split('?')[1];
      const params = queryString.split('&');
      let id = null;
      for (let i = 0; i < params.length; i++) {
        const param = params[i].split('=');
        if (param[0] === 'id') {
          id = param[1];
          break;
        }
      }
      
      saveProgress({
        'percentage':percentage,
        'account':account,
        'videoid':id,
      });

    });

    if (portalSection) {
      video.addEventListener('ended', function () {
        window.location.reload();
      });
    } else {
      video.addEventListener('ended', function () {
        console.log('Video Played Ended', percentage);
      });
    }

    video.addEventListener('timeupdate', function () {
      duration = video.duration || 0;
      current_time = video.currentTime || 0;
      played_time = Math.floor(current_time)
      compare_time = current_time / duration * 100;
      percentage = Math.floor(compare_time)
    });		

  }

  // Welcome home video
  const videoHome = document.getElementById('videoHomePlayerHome');

  if (videoHome) {
    // Hide default videoHome controls
    videoHome.controls = false;

    videoHome.play();

    videoHome.addEventListener('click', function () {
      videoHome.play();
      videoHome.controls = true;
    });

    videoHome.addEventListener('play', function () {
      //playBtn.classList.add('hidden');
    });

    videoHome.addEventListener('pause', function () {
      videoHome.play();
    });
    video.addEventListener('ended', function () {
      window.location.reload();
    });
  }

  // PROGESS
  const progressContainer = document.querySelectorAll('.progress-container');
  progressContainer.forEach(data => {
    const percentage = data.getAttribute('data-percentage') + '%';
    const progressEl = data.querySelector('.progress');
    if (progressEl) {
      progressEl.style.width = percentage;
    }
  });

};

export default Portal;
import {loginUser} from "../services/UserServices"

async function handleLogin(event) {
  event.preventDefault(); // Prevent default form submission

  // Extract email and password from form fields
  const emailInput = document.getElementById("login-form__email");
  const passwordInput = document.getElementById("login-form__name");
  const errorMessage = document.getElementById("login-failure-message");

  const email = emailInput.value;
  const password = passwordInput.value;

  // Validate email and password
  let isValid = true;
  if (email.trim() === "") {
    emailInput.classList.add("error-field");
    isValid = false;
  } else {
    emailInput.classList.remove("error-field");
  }
  if (password.trim() === "") {
    passwordInput.classList.add("error-field");
    isValid = false;
  } else {
    passwordInput.classList.remove("error-field");
  }

  if (!isValid) {
    // If fields are not valid, don't proceed with login
    return;
  }

  try {
    const result = await loginUser({ Email: email, Password: password });
    if (result.status === false) {
      errorMessage.innerText = result.message;
      errorMessage.style.display = "block";
    } else {
      window.localStorage.setItem("account", email);
      window.location.href = "member-portal";
    }
  } catch (error) {
    console.error("Error during login:", error);
  }
}

const LoginForm = () => {

  const loginForm = document.querySelector(".login-form__content form");
  const errorMessage = document.getElementById("login-failure-message");
  if (loginForm) {
    loginForm.addEventListener("submit", handleLogin);

    loginForm.addEventListener("input", function() {
      errorMessage.style.display = "none";
    });
  }

  const passwordInput = document.getElementById("login-form__name");
  const eyeIcon = document.querySelector(".eye-icon");
  
  // Add click event listener to toggle password visibility
  eyeIcon.addEventListener("click", function() {
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  });
  
};

export default LoginForm;
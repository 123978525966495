
export const updateURLParams = (url, param, paramVal) => {
  var TheAnchor = null;
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";

    if (additionalURL) 
    {
        var tmpAnchor = additionalURL.split("#");
        var TheParams = tmpAnchor[0];
            TheAnchor = tmpAnchor[1];
        if(TheAnchor)
            additionalURL = TheParams;

        tempArray = additionalURL.split("&");

        for (var i=0; i<tempArray.length; i++)
        {
            if(tempArray[i].split('=')[0] != param)
            {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }        
    }
    else
    {
        var tmpAnchor = baseURL.split("#");
        var TheParams = tmpAnchor[0];
            TheAnchor  = tmpAnchor[1];

        if(TheParams)
            baseURL = TheParams;
    }

    if(TheAnchor)
        paramVal += "#" + TheAnchor;

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
};

export const removeURLParameter = (url, parameter) => {
    
    var urlParts = url.split('?');

    if (urlParts.length >= 2) {
        // Get first part, and remove from array
        var urlBase = urlParts.shift();

        // Join it back up
        var queryString = urlParts.join('?');

        var prefix = encodeURIComponent(parameter) + '=';
        var parts = queryString.split(/[&;]/g);

        // Reverse iteration as may be destructive
        for (var i = parts.length; i-- > 0; ) {
        // Idiom for string.startsWith
        if (parts[i].lastIndexOf(prefix, 0) !== -1) {
            parts.splice(i, 1);
        }
        }

        url = urlBase + '?' + parts.join('&');
    }

    return url;
}

export const checkIfInURL = (type, id) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (type == 'category') {
      let categoryList = [];
      if (typeof params.c !== 'undefined') {
        const category = params['c'];
        if (category.length > 0) {
          if (category.includes(',')) {
            var categoryChunks = category.split(',');
            categoryList = categoryChunks;
          } else {
            categoryList.push(category);
          }
        }
        return categoryList.includes(id.toString()) ? true : false;
      } else {
        return false;
      }
    }

    if (type == 'tags') {
      let tagList = [];
      if (typeof params.t !== 'undefined') {
        const tag = params['t'];
        if (tag.length > 0) {
          if (tag.includes(',')) {
            var tagChunks = tag.split(',');
            tagList = tagChunks;
          } else {
            tagList.push(tag);
          }
        }
        return tagList.includes(id.toString()) ? true : false;
      } else {
        return false;
      }
    }

    if (type == 'place') {
      let placeList = [];
      if (typeof params.l !== 'undefined') {
        const location = params['l'];
        if (location.length > 0) {
          if (location.includes(',')) {
            var locationChunks = location.split(',');
            placeList = locationChunks;
          } else {
            placeList.push(location);
          }
        }
        return placeList.includes(id.toString()) ? true : false;
      } else {
        return false;
      }
    }

    if (type == 'price') {
      let priceList = [];
      if (typeof params.p !== 'undefined') {
        const price = params['p'];
        if (price.length > 0) {
          if (price.includes(',')) {
            var priceChunks = price.split(',');
            priceList = priceChunks;
          } else {
            priceList.push(price);
          }
        }
        return priceList.includes(id.toString()) ? true : false;
      } else {
        return false;
      }
    }
}
import {sendForgetPassword} from "../services/UserServices"

async function handleForget(event) {
  event.preventDefault(); 

  // Extract email and password from form fields
  const emailInput = document.getElementById("forgot-password__email");
  const errorMessage = document.getElementById("forget-failure-message");
  const successMessage = document.getElementById("forget-success-message");
  const detail = document.getElementById("forgot-password-detail");

  const email = emailInput.value;

  // Validate email and password
  let isValid = true;
  if (email.trim() === "") {
    emailInput.classList.add("error-field");
    isValid = false;
  } else {
    emailInput.classList.remove("error-field");
  }

  if (!isValid) {
    return;
  }

  try {
    const result = await sendForgetPassword({ Email: email });
    if (result.status === false) {
      errorMessage.innerText = result.message;
      errorMessage.style.display = "block";
    } else {
      successMessage.innerText = result.message;
      successMessage.style.display = "block";
      detail.style.display = "none";
    }
  } catch (error) {
    console.error("Error during login:", error);
  }
}

const ForgetForm = () => {

  const forgetForm = document.querySelector("#forgot-password-form form");
  if (forgetForm) {
    forgetForm.addEventListener("submit", handleForget);

    forgetForm.addEventListener("input", function() {
      const errorMessage = document.getElementById("login-failure-message");
      errorMessage.style.display = "none";
    });
  }
  
  const loginForm = document.getElementById('login-form');
  const forgotPasswordForm = document.getElementById('forgot-password-form');
  const forgotPasswordLink = document.getElementById('forgot-password-link');

  // Event listener for clicking on the "Forgot Password?" link
  forgotPasswordLink.addEventListener('click', function(event) {
    event.preventDefault(); // Prevent default link behavior
    loginForm.style.display = 'none'; // Hide the login form
    forgotPasswordForm.style.display = 'block'; // Show the forgot password form
  });

  
};

export default ForgetForm;
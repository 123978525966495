import { isEmpty } from '../utils';

const headers = {
  "Content-Type": "application/json",                                                                                                
  "Access-Control-Origin": "*"
}

export const loginUser = async (values) => {
  const fetchResp = await fetch(`/api-portal/login`,{
      method: "POST",
      headers: headers,
      body:  JSON.stringify(values)
  });
  return await fetchResp.json();
}

export const checkIfLogin = () => {
  if (isEmpty(window.localStorage.getItem("account"))) {
    return false;
  }
  return true;
}

export const redirectToLogin = () => {
  window.location.href = '/login';
}

export const redirectToDashboard = () => {
  window.location.href = '/member-portal';
}

export const fetchUserAccount = async () => {
  const values = {
    'email': window.localStorage.getItem("account"),
  };
  const fetchResp = await fetch(`/api-portal/account`,{
      method: "POST",
      headers: headers,
      body:  JSON.stringify(values)
  });
  return await fetchResp.json();
}

export const sendForgetPassword = async (values) => {
  const fetchResp = await fetch(`/api-portal/forgot-password`,{
      method: "POST",
      headers: headers,
      body:  JSON.stringify(values)
  });
  return await fetchResp.json();
}

export const saveProgress = (values) => {
  fetch(`/api-portal/save-progress`,{
      method: "POST",
      headers: headers,
      body:  JSON.stringify(values)
  });
}


export const logoutMember = async (values) => {
  const fetchResp = await fetch(`/api-portal/logout`,{
      method: "POST",
      headers: headers,
      body:  JSON.stringify(values)
  });
  return await fetchResp.json();
}
import ContactForm from "./contact/index.js";
import LoginForm from "./login/index.js";
import Portal from "./portal/index.js";
import ForgetForm from "./forgot/index.js";

import {
    checkIfLogin,
    redirectToLogin, 
    redirectToDashboard,
    logoutMember } from './services/UserServices.js';

window.addEventListener('load', async function(event) {
    ContactForm();

    const loginElement  = document.getElementById('login-area');
    if  (typeof(loginElement) != 'undefined' && loginElement != null){
        if (checkIfLogin()) {
            redirectToDashboard();
        } else {
            LoginForm();
            ForgetForm();
        }
    }

    const portalElement  = document.getElementById('portal-area');
    if  (typeof(portalElement) != 'undefined' && portalElement != null){
        // scroll to top
        window.scrollTo(0, 0);

        if (checkIfLogin()) {
            Portal();
        } else {
            redirectToLogin();
        }
    }

    const logoutLink = document.querySelector('.member-link');
    
    // if (checkIfLogin()) {
    //     logoutLink.href = '#';
    //     logoutLink.querySelector('.phone-link__text').textContent = 'Logout';
    //     logoutLink.classList.add('logout-link');
    // }

    // Add event listener for click event
    logoutLink.addEventListener('click', async function(event) {
        event.preventDefault();

        await logoutMember();

        window.localStorage.removeItem('account');
        window.location.href = '/login';
    });


});